import luxy from 'luxy.js';
import ScrollTrigger from '@terwanerik/scrolltrigger';
import easing from 'jquery.easing';
import simpleParallax from 'simple-parallax-js';

export class App {
  constructor(){
    this.body = document.body;
    this.page = document.getElementById('page');
    this.hero = document.getElementById('heroContainer');
    this.ua = isSp(this);
    this.sp = 900;
    this.ease = "easeInOutExpo";
    this.btn_jud = false;
    this.jud_num = 50;


    function isSp(_t){
    let _ua_ar=[];
    let _ua;
    if(navigator.userAgent.match(/iPhone|iPad|iPod|Android.+Mobile/)){
      _ua_ar.push("mobile");
      _ua = "mobile";
      if(navigator.userAgent.match(/iPhone|iPod|Android.+Mobile/)){
        _ua_ar.push("m_sp");
      }
      if(navigator.userAgent.match(/iPad/)){
        // _ua_ar.push("m_pd");
      }
      // _t.makeSp();
      
      console.log(navigator.userAgent);
    }else{
      _ua = "other";
      _ua_ar.push("other");
    }
    _ua_ar.forEach(_u => {
      _t.body.classList.add(_u);
    });
    //_t.body.classList.add(_ua_ar);
    return _ua;
  }

  }
  init(){
    window.addEventListener('load',(e)=>{
      const _this = this;
      setTimeout(()=>{
        this.resize();
        this.scroll();
        this.page.classList.add('on');
        $('.spinner').fadeOut('slow',function(){
          $(this).remove();
        });
        // document.querySelector('.hero_image').classList.add('on');
        // if(_this.body.classList.contains('home')){
        //   if(ref_jud){
        //     _this.hero_swiper.autoplay.start();
        //   }
        // }
        this.trigger();
        this.events();
      },500);
    });
    //console.log('init');

    // this.animate = document.querySelector('.heroContainer__svgContainerBt animate');
   

    // this.trigger();

  //   if(this.ua !== "mobile"){
  //     luxy.init({
  //      wrapper:"#page",
  //      targets:'.lxel',
  //      wrapperSpeed:0.098//0.095
  //     });
  //   }else{
  //     const elm = document.querySelector('.heroImage');
  //     const parallaxConfig = {
  //     delay: 0,
  //     orientation: "down",
  //     scale: 1.3,
  //     overflow: false,
  //     //customContainer:'.sec2',
  //     customWrapper:".img_wrap"
  //     //maxTransition : 50
  //   };

  //  const parallax_instance = new simpleParallax(elm, parallaxConfig);

  //   }


  }
  anim(_t){
    const rect = _t.getBoundingClientRect();
    const _scT =  window.pageYOffset || document.documentElement.scrollTop;
      console.log(_scT);

    $('html,body').animate({
      scrollTop:rect.top + _scT
    },this.sp,this.ease);
  }
  scroll(){
    let scT = window.pageYOffset || document.documentElement.scrollTop;
    console.log(this.ua);
    if(this.ua !== "other"){
     
    }else{

      
    }
  }
  resize(){

    this.getSize();
    if(this.ua !== "other"){

      // this.hero.style.height = (this.winH - 120) + "px";
    }
  }
  getSize(){
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
  }
  events(){
    
    // window.addEventListener('scroll',(e)=>{this.scroll();});
    //window.addEventListener('resize',(e)=>{this.resize();});

    const atags = document.querySelectorAll("a[href^='#']");
    for(const _a of atags){
      _a.addEventListener('click',(e)=>{
        e.preventDefault();
        let href = e.currentTarget.getAttribute('href');
        if(href == "#" || href == ""){
          href = "#page";
          this.sp = 1200;
        }
        href = href.replace("#","");
        const target = document.getElementById(href);
        setTimeout((e)=>{
          this.anim(target);
        },100);
      });
    }

  }
  trigger(){
    this.trigger = new ScrollTrigger({
      trigger: {
        offset: {
          viewport: {
            x: 0,
            y: (trigger, rect, direction) => {
              return 0.1;
            }
          }
        },
        toggle: {
          callback: {
            in:(trigger)=>{
            },
            out:(trigger)=>{
            }
          }
        }
      }
    });
    this.trigger.add('[data-scroll]', {
      once: true,
      toggle: {
        class: {
          in: 'animIn',
          out: 'animOut'
        },
        callback: {
          in:(trigger) => {
            
          },
          out:(trigger) => {

          }
        }
      }
    })
  }
  makeSp(){
    const btnby = document.querySelector('.btn_buy');
    const buUrl = btnby.getAttribute('href');
    this.btn_sp = document.createElement('a');
    this.btn_sp.classList.add('btnSp');
    this.btn_sp.setAttribute('href',buUrl);
    this.btn_sp.setAttribute('target','_blank');
    this.btn_sp.innerHTML = `<div class='btnSp-inner'>
    <span class="cart"><img src="images/img_btn_under_cart.png"></span>
    <span class="text"><img src="images/img_btn_under_text.png" ></span>
    </div>`;
    this.body.appendChild(this.btn_sp);
  }
}
